import { ref } from 'vue';

export type InputSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const useInput = () => {
  // Data
  const inputContainer = ref<HTMLDivElement | null>(null);

  const sizes = ref<{ name: InputSize; textSize: string; height: string }[]>([
    {
      name: 'xs',

      textSize: 'text-[12px]',
      height: 'h-[28px]',
    },
    {
      name: 'sm',
      textSize: 'text-[14px]',
      height: 'h-[30px]',
    },
    {
      name: 'md',
      textSize: 'text-[14px]',
      height: 'h-[32px]',
    },
    {
      name: 'lg',
      textSize: 'text-[14px]',
      height: 'h-[34px]',
    },
    {
      name: 'xl',
      textSize: 'text-[14px]',
      height: 'h-[36px]',
    },
  ]);

  const getTextSize = (size: (typeof sizes.value)[number]['name']) => {
    return sizes.value.find((s) => s.name === size)?.textSize;
  };

  const getHeight = (size: (typeof sizes.value)[number]['name']) => {
    return sizes.value.find((s) => s.name === size)?.height;
  };

  const getSelectOpenAnimation = () => {
    return 'will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade';
  };

  const getInputColor = (options: { errorMessage: string | undefined; disabled: boolean; open: boolean }) => {
    if (options.errorMessage) return 'border-red-300';
    else if (options.disabled) return 'border-gray-200';
    else if (options.open) return 'border-primary-500 ring-primary-100 ring-[2px]';
    return 'border-gray-200 hover:border-gray-300 hover:ring-gray-100 cursor-pointer hover:ring-[2px]';
  };

  return {
    sizes,
    inputContainer,
    getTextSize,
    getHeight,
    getInputColor,
    getSelectOpenAnimation,
  };
};
